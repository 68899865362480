<template>
  <div id="app">
    <!-- slot for header -->
    <app-header></app-header>
    <!-- 语音播报的位置 -->
    <broadcast/>
    <!-- slot for content -->
    <!-- 距离底部的布局高度会根据footer输入框的状态改变 -->
    <div
      class="app-content"
      :style="{ bottom: ui.footerHeight + 'px' }"
      ref="wrapper"
    >
      <div
        class="chat-list"
        ref="content"
      >
        <chat-bubble
          v-for="(item, index) in messages"
          :key="index"
          :message="item"
          ref="chatBubble"
        ></chat-bubble>
      </div>
    </div>
    <!-- Put it at the end of App.vue -->
    <dialogs-wrapper wrapper-name="default"/>
    <!-- slot for footer -->
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ChatBubble from '@/components/ChatBubble'
import { mapState } from 'vuex'
import BetterScroll from 'better-scroll'
import { throttle } from 'lodash'

export default {
  name: 'App',
  data () {
    return {
      stateScroll: false,
      scroller: null
    }
  },
  computed: {
    ...mapState(['messages', 'ui', 'freshNeed'])
  },
  watch: {
    messages () {
      this.scrollToEnd(true)
    },
    ui () {
      this.scrollToEnd()
    },
    freshNeed () {
      this.$store.commit('updateFreshNeed', false)
      this.scrollToEnd()
    }
  },
  methods: {
    scrollToEnd: (function () {
      let prevIndex = 0
      return throttle(function (toBottom = false) {
        setTimeout(() => {
          if (this.scroller) {
            // 刷新 scroller 内容的高度
            this.scroller.refresh()
            // 根据具体的场景判断， scroller 是否需要滚动到底部
            if (!this.stateScroll) {
              // 初始状态下的对话需要从头展示列表数据
              this.scroller.scrollToElement(0, 0, 0)
              this.stateScroll = true
            } else if (toBottom) {
              // 对话引起的页面内容滚动，应该是定位到最新的提问，同时展示问答的数据
              let i = this.messages.length - 1
              let hasUserInput = false
              for (; i > prevIndex; i--) {
                const message = this.messages[i]
                if (message.from === 2 || message.type === 0) {
                  hasUserInput = true
                  break
                }
              }
              prevIndex = i
              console.log(prevIndex)
              const maxScrollY = this.scroller.maxScrollY
              const offset = hasUserInput ? Math.max(-this.$refs.chatBubble[i].$el.offsetTop + 10, maxScrollY) : maxScrollY
              console.log(maxScrollY, offset)
              this.scroller.scrollTo(0, offset, 0)
              prevIndex = this.messages.length - 1
              // 在首次进入页面时我们给window.name设置一个固定值(isRefresh)
              window.name = 'refresh'
            }
          }
        }, 200)
      }, 300, {
        leading: false,
        trailing: true
      })
    })()
  },
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
    [ChatBubble.name]: ChatBubble,
    Broadcast: () => import('@/components/Broadcast')
  },
  created () {
    this.$store.dispatch('initApp')
  },
  mounted () {
    setTimeout(() => {
      this.scroller = new BetterScroll(this.$refs.wrapper, {
        startY: 1000,
        scrollY: true,
        click: true,
        probeType: 1,
        mouseWheel: true,
        autoEndDistance: 0,
        preventDefault: false
      })
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
// 定义整体的布局结构
$app-gap: 0.08rem;
#app {
  position: relative;
  z-index: 1;
  height: 100%;
  background: $app-bgc;
  margin: 0 $app-gap;
  .app-content {
    position: absolute;
    z-index: 1;
    top: $header-height;
    right: 0;
    bottom: $footer-height + 20px; // ?????
    padding-bottom: 0.4rem;
    left: 0;
    background: $chat-bgc;
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>
